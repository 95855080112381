import { Link, NavLink } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import gsap from 'gsap'

function Header() {
  const [showMenu, setShowMenu] = useState(false)
  const comp = useRef(null)

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (!showMenu) {
        gsap.to('.toggleMenu', {
          yPercent: -100,
          height: 0,
        })
      }
      if (showMenu) {
        let tl = gsap.timeline()
        tl.to('.toggleMenu', {
          yPercent: 0,
          height: '100%',
        }).fromTo(
          '.menuContents',
          {
            opacity: 0,
            yPercent: 100,
          },
          {
            opacity: 1,
            stagger: 0.05,
            yPercent: 0,
          }
        )
      }
      return () => ctx.revert()
    }, comp)
  }, [showMenu])

  const handleShowMenu = () => {
    setShowMenu((prev) => !prev)
  }

  const handleStatusChange = (e) => {
    e.target.checked = showMenu
  }

  return (
    <div
      ref={comp}
      className="container py-8 flex items-center justify-between md:block md:py-14"
    >
      <Link to="/">
        <img
          className="w-[15rem] md:w-[31rem]"
          src="images/miles_ahead_logo.png"
          alt="Miles Ahead"
        />
      </Link>

      {/* hamburger menu */}
      <label className="fixed top-[2rem] right-[2rem] swap swap-rotate z-50 md:hidden">
        <input
          type="checkbox"
          onClick={handleShowMenu}
          onChange={handleStatusChange}
          checked={showMenu}
        />
        <svg
          className="swap-off fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
        </svg>
        <svg
          className="swap-on fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
        </svg>
      </label>

      {/* menu for smartphone */}
      <div className="toggleMenu fixed top-0 left-0 w-full h-0 bg-gradient-to-br from-cyan-500 to-blue-500 z-40">
        {showMenu && (
          <ul className="flex flex-col items-center justify-center h-full text-xl gap-16 text-[#d5eafd]">
            <li className="menuContents">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? 'active-sp' : '')}
                onClick={(prev) => setShowMenu(!prev)}
              >
                ご挨拶
              </NavLink>
            </li>
            <li className="menuContents">
              <NavLink
                to="/works"
                className={({ isActive }) => (isActive ? 'active-sp' : '')}
                onClick={(prev) => setShowMenu(!prev)}
              >
                業務内容
              </NavLink>
            </li>
            <li className="menuContents">
              <NavLink
                to="/achievement"
                className={({ isActive }) => (isActive ? 'active-sp' : '')}
                onClick={(prev) => setShowMenu(!prev)}
              >
                これまでの実績
              </NavLink>
            </li>
            <li className="menuContents">
              <NavLink
                to="/company"
                className={({ isActive }) => (isActive ? 'active-sp' : '')}
                onClick={(prev) => setShowMenu(!prev)}
              >
                会社概要
              </NavLink>
            </li>
            <li className="menuContents">
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? 'active-sp' : '')}
                onClick={(prev) => setShowMenu(!prev)}
              >
                お問い合わせ
              </NavLink>
            </li>
          </ul>
        )}
      </div>

      {/* pc / tablet  */}
      <div className="hidden md:block mt-14">
        <ul className="flex gap-10 text-sm">
          <li className="transition">
            <NavLink
              to="/"
              className={`py-1 ${({ isActive }) => (isActive ? 'active' : '')}`}
            >
              ご挨拶
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/works"
              className={`py-1 ${({ isActive }) => (isActive ? 'active' : '')}`}
            >
              業務内容
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/achievement"
              className={`py-1 ${({ isActive }) => (isActive ? 'active' : '')}`}
            >
              これまでの実績
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/company"
              className={`py-1 ${({ isActive }) => (isActive ? 'active' : '')}`}
            >
              会社概要
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={`py-1 ${({ isActive }) => (isActive ? 'active' : '')}`}
            >
              お問い合わせ
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header
