function Thanks() {
  return (
    <div className="grow container relative md:flex">
      <div className="greeting text-sm leading-loose mt-5 md:w-1/2">
        お問い合わせいただきありがとうございました。
        内容確認後、担当者からご連絡させていただきます。
        48時間経っても返信がない場合は、大変申し訳ありませんが下記アドレスからご連絡ください。
        <a href="mailto:tsukasa.yokokura@miles-ahead.co.jp">
          tsukasa.yokokura@miles-ahead.co.jp
        </a>
      </div>

      <div className="hidden md:block absolute bottom-0 right-0 z-10 w-1/2">
        <img
          className="yokokura lg:w-[80%]"
          src="images/tsukasa_yokokura.png"
          alt="横倉典"
        />
      </div>
    </div>
  )
}

export default Thanks
