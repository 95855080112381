function Achievement() {
  return (
    <div className="grow container relative md:flex">
      <div className="greeting text-sm leading-loose mt-5 md:w-1/2">
        <h1 className="text-lg mb-5 md:hidden">■ これまでの実績</h1>
        <h2 className="font-bold text-xl border-b pb-3 border-gray-300 mb-5">
          英国系ラグジュアリー自動車ブランド
        </h2>
        <ul className="list-disc ml-5">
          <li>メディア向け新型車発表会企画・運営</li>
          <li>メディア向け新型車試乗会企画・運営</li>
          <li>メディア向けインタビュー/ワークショップ企画・運営</li>
          <li>プレスリリース作成</li>
          <li>プレス向けニュースレター作成</li>
          <br />
          <li>SNS運営（コンテンツ制作）</li>
          <li>メディアバイイング・タイアップ記事企画</li>
          <li>ディーラーマーケティング</li>
          <li>ディーラー向けマーケティングツール制作</li>
          <li>お客様向けツーリングイベント企画・運営</li>
          <li>ポップアップストア企画・運営</li>
        </ul>
        <p className="bg-white p-5 border mt-5 text-primary">
          上記の活動により、この10年間でメディアカバレッジを４.７倍伸ばすことができました。
        </p>
      </div>

      <div className="hidden md:block absolute bottom-0 right-0 z-10 w-1/2">
        <img
          className="yokokura lg:w-[80%]"
          src="images/tsukasa_yokokura.png"
          alt="横倉典"
        />
      </div>
    </div>
  )
}

export default Achievement
