function Company() {
  return (
    <div className="grow container relative md:flex">
      <div className="greeting text-sm leading-loose mt-5 md:w-1/2">
        <h1 className="text-lg mb-5 md:hidden">■ 会社概要</h1>
        <table className="table-auto">
          <tbody>
            <tr className="border-b">
              <td className="py-3 px-5 w-24">会社名</td>
              <td className="p-3">合同会社マイルスアヘッド</td>
            </tr>
            <tr className="border-b">
              <td className="p-3 px-5 w-24">代表社員</td>
              <td className="p-3">
                横倉典<span className="text-xs">（よこくらつかさ）</span>
              </td>
            </tr>
            <tr className="border-b">
              <td className="p-3 px-5 w-24">設立</td>
              <td className="p-3">2023年3月20日</td>
            </tr>
            <tr className="border-b">
              <td className="p-3 px-5 w-24">資本金</td>
              <td className="p-3">3,000,000円</td>
            </tr>
            <tr className="border-b">
              <td className="p-3 px-5 w-24">住所</td>
              <td className="p-3">千葉県千葉市美浜区</td>
            </tr>
            <tr>
              <td className="p-3 px-5 w-24">事業内容</td>
              <td className="p-3">
                マーケティング・広報活動のプランニング業務
                <br />
                自動車関連イベントの実施
                <br />
                自動車関連資料の作成・翻訳
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="hidden md:block absolute bottom-0 right-0 z-10 w-1/2">
        <img
          className="yokokura lg:w-[80%]"
          src="images/tsukasa_yokokura.png"
          alt="横倉典"
        />
      </div>
    </div>
  )
}

export default Company
