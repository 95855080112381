import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useRef, useEffect } from 'react'
gsap.registerPlugin(ScrollTrigger)

function Home() {
  const comp = useRef(null)

  useEffect(() => {
    const ctx = gsap.context(() => {
      let mm = gsap.matchMedia()

      // mobile
      mm.add('(max-width: 767px)', () => {
        let tl = gsap.timeline()
        tl.from('.yokokura', {
          y: 200,
          opacity: 0,
          duration: 0.8,
        })
        tl.from('.catchcopy', {
          yPercent: 50,
          opacity: 0,
          delay: 0.3,
          duration: 0.8,
        })
        tl.to('.yokokura', {
          x: 500,
          duration: 3,
          scrollTrigger: {
            trigger: '.yokokura',
            scrub: true,
            start: 'center center',
          },
        })
      })

      // pc
      mm.add('(min-width: 768px)', () => {
        let tl = gsap.timeline()
        tl.from('.yokokura', {
          y: 100,
          opacity: 0,
          duration: 0.8,
          scale: 0.9,
        })
        tl.from('.greeting', {
          y: 30,
          opacity: 0,
          delay: 0.3,
          duration: 0.5,
        })
      })
    }, comp)
    return () => ctx.revert()
  }, [])

  return (
    <div ref={comp} className="grow container relative md:flex">
      {/* only smartphone */}
      <div className="relative w-full mt-3 flex flex-col items-center md:hidden">
        <p className="catchcopy text-center leading-loose bg-white py-5 shadow w-full">
          自動車業界を得意とする
          <br />
          マーケティング・広報のプランニング会社です
        </p>
        <img
          className="yokokura fixed bottom-0 h-[33rem]"
          src="images/tsukasa_yokokura.png"
          alt="横倉典"
        />
      </div>
      <div className="h-screen md:hidden"></div>

      <div className="greeting text-sm leading-loose md:w-1/2">
        {/* only pc */}
        <div className="hidden md:block mb-8 mt-2">
          <p className="text-xl leading-relaxed">
            自動車業界を得意とする
            <br />
            マーケティング・広報のプランニング会社です
          </p>
        </div>
        <p className="mb-5">
          30年間の輸入車業界の経験を基に、自動車メーカー、インポーター、ディーラーのマーケティング・広報活動並びにラグジュアリーブランドのマーケティングを
          サポートする会社「合同会社マイルスアヘッド」を設立いたしました。
        </p>
        <p className="mb-5">
          日頃の皆様のマーケティング・広報活動の中で「やりたいけれど時間がない」
          「やりたいけれど人手が足りない」といった小さなイベントやプロモーションのプランニングを得意としています。
        </p>
        <p className="mb-5">
          業界の中で「一歩先をゆく」マイルスアヘッドな活動を考えていらっしゃる現場のプランナーの皆さまのお役に立てるような会社になってゆくことを目指しております。
        </p>
        <p className="mb-10">
          イベント・プロモーションを成功に導くための豊富なノウハウを持った協力会社も多数契約しております。
          お困りごとがある際にはぜひお気軽にお問い合わせください。
        </p>
        <p className="mb-20">
          合同会社マイルスアヘッド
          <br />
          <span className="inline-block mr-3">代表社員</span> 横倉 典
        </p>
      </div>

      <div className="hidden md:block absolute bottom-0 right-0 z-10 w-1/2">
        <img
          className="yokokura lg:w-[80%]"
          src="images/tsukasa_yokokura.png"
          alt="横倉典"
        />
      </div>
    </div>
  )
}

export default Home
