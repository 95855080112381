import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Pages/Home'
import Works from './Pages/Works'
import Achievement from './Pages/Achievement'
import Company from './Pages/Company'
import Contact from './Pages/Contact'
import Thanks from './Pages/Thanks'

function App() {
  return (
    <div className="bg-gradient-to-br from-white to-gray-200 min-h-screen flex flex-col justify-between">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/works" element={<Works />} />
          <Route path="/achievement" element={<Achievement />} />
          <Route path="/company" element={<Company />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
