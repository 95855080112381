function Works() {
  return (
    <div className="grow container relative md:flex">
      <div className="greeting text-sm leading-loose mt-5 md:w-1/2">
        <div className="flex flex-col mb-5 lg:flex-row">
          <h1 className="text-lg mb-5 md:hidden">■ 業務内容</h1>
          <h2 className="bg-gray-200 w-full px-5 py-1 rounded-sm mb-2 lg:w-48 flex items-center justify-center">
            広報業務
          </h2>
          <ul className="list-disc ml-5 lg:ml-10">
            <li>プレスリリース作成</li>
            <li>プレス向けイベント企画（発表会・試乗会等）</li>
            <li>製品プロモーション・メディア向けワークショップ</li>
            <li>メディアリレーション</li>
            <li>スピーチライティング等</li>
          </ul>
        </div>
        <div className="flex flex-col mb-5 lg:flex-row">
          <h2 className="bg-gray-200 w-full px-5 py-1 rounded-sm mb-2 lg:w-48 flex items-center justify-center">
            マーケティング
          </h2>
          <ul className="list-disc ml-5 lg:ml-10">
            <li>メディア戦略</li>
            <li>顧客向けイベント企画</li>
            <li>製品プロモーション企画</li>
            <li>SNSプロモーション等</li>
          </ul>
        </div>
        <div className="flex flex-col mb-5 lg:flex-row">
          <h2 className="bg-gray-200 w-full px-5 py-1 rounded-sm mb-2 lg:w-48 flex items-center justify-center">
            ドキュメンテーション
          </h2>
          <ul className="list-disc ml-5 lg:ml-10">
            <li>製品プレゼンテーション作成</li>
            <li>SNSコンテンツ作成</li>
            <li>製品レポート</li>
            <li>マーケティングレポート</li>
            <li>パワーポイント・リデザイン</li>
            <li>市場分析等</li>
          </ul>
        </div>
      </div>

      <div className="hidden md:block absolute bottom-0 right-0 z-10 w-1/2">
        <img
          className="yokokura lg:w-[80%]"
          src="images/tsukasa_yokokura.png"
          alt="横倉典"
        />
      </div>
    </div>
  )
}

export default Works
