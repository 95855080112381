function Footer() {
  return (
    <div className="bg-gray-300 w-full py-5 z-30">
      <div className="container text-xs">
        Copyright &copy; 2023 Miles Ahead inc. All Rights Reserved.
      </div>
    </div>
  )
}

export default Footer
